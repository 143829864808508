import { inject, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject, map } from 'rxjs';
import { CURRENCY } from '@currency';
import { StorageService } from '@app/shared/services';
import { Storage } from '@app/shared/models';

type LANGS_AVAILABLE = (typeof environment.currency.available)[number];

@Injectable({
    providedIn: 'root',
})
export class CurrencyService {
    private storageService = inject(StorageService);

    readonly currencies = environment.currency.available;
    readonly currency$ = new BehaviorSubject<CURRENCY>(environment.currency.default);

    readonly currencyCode$ = this.currency$.pipe(
        map((currency) => {
            switch (currency) {
                case 'PLN':
                    return 'PLN';
                case 'EUR':
                    return 'EUR';
                case 'RON':
                    return 'RON';
                default:
                    return 'PLN';
            }
        }),
    );

    constructor() {
        const currency = this.storageService.getItem('currency');
        if (this.isLang(currency)) {
            this.currency$.next(currency);
        }
    }

    set(currency: CURRENCY): void {
        if (currency !== this.get()) {
            this.currency$.next(currency);
            this.storageService.setItem('currency', currency);
        }
    }

    get() {
        return this.currency$.value;
    }

    isLang(lang: Storage | string | null): lang is LANGS_AVAILABLE {
        return environment.currency.available.findIndex((available) => available === lang) !== -1;
    }
}
